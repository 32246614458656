import ApplicationLogo from "@/Components/ApplicationLogo";
import ThemeHook from "@/Components/ThemeHook";
import { classNames } from "@/Utils/theme";
import { Link, usePage } from "@inertiajs/react";
import { PropsWithChildren, useEffect } from "react";

export default function Guest({ className, children }: PropsWithChildren) {
    const style = classNames(
        "relative w-full sm:max-w-md  px-6 py-4 bg-white shadow-md overflow-hidden sm:rounded-lg mb-8",
        className
    );

    return (
        <div className="h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0 bg-light-green">
            <ThemeHook />
            <img
                id="background"
                className="absolute left-0 right-0 top-0 bottom-0 w-full h-full"
                src="/svg/spotlight.svg"
            />

            <div
                className="bg-repeat absolute left-0 right-0 top-0 bottom-0 opacity-40 mix-blend-color-burn	"
                style={{ backgroundImage: "url(/svg/noise.svg)" }}
            ></div>

            <div className="my-8 z-10">
                <Link href="/">
                    <ApplicationLogo className="w-20 h-20 fill-current text-gray-500" />
                </Link>
            </div>

            <div className={style}>{children}</div>
        </div>
    );
}
