import { usePage } from "@inertiajs/react";
import { use } from "i18next";
import { SVGAttributes, useEffect } from "react";

export default function ApplicationLogo(props: SVGAttributes<SVGElement>) {
    const { branding } = usePage().props;

    useEffect(() => {
        console.log(branding);
    }, [branding]);

    return (
        <div className="flex flex-col items-center">
            {!!branding?.logo && (
                <img
                    className="w-32 h-auto mb-6"
                    src="/svg/logo_sort.svg"
                ></img>
            )}
            <img
                className={`h-12 w-auto  ${
                    !!branding?.logo &&
                    "p-1 bg-white transparent bg-opacity-40  rounded-lg "
                }`}
                src={branding?.logo ? branding.logo : "/svg/logo_sort.svg"}
            ></img>
        </div>
    );
}
